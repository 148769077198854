<template>
  <standard>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>

    <section id="intro" class="hero is-fullheight is-small">
      <div class="hero-head">
        <div class="notification is-black m-0 p-1">
          <p class="has-text-centered"><span class="has-text-weight-bold mr-4">Film ansehen</span> <a href="#stream" target="_blank" rel="noopener">Jetzt streamen</a></p>
        </div>
        <div class="level actors mt-4 is-hidden-touch">
          <div class="level-item is-narrow has-text-centered">
            <p>
              <strong class="has-text-white is-block">Alexander Gerst</strong>
              Astronaut
            </p>
          </div>
          <div class="level-item is-narrow has-text-centered">
            <p>
              <strong class="has-text-white is-block">Sylvia Earle</strong>
              Ozeanologin
            </p>
          </div>
          <div class="level-item is-narrow has-text-centered">
            <p>
              <strong class="has-text-white is-block">Dennis Snower</strong>
              Ökonom
            </p>
          </div>
          <div class="level-item is-narrow has-text-centered">
            <p>
              <strong class="has-text-white is-block">Felwine Sarr</strong>
              Philosoph
            </p>
          </div>
          <div class="level-item is-narrow has-text-centered">
            <p>
              <strong class="has-text-white is-block">Janina Loh</strong>
              Kritische Posthumanistin
            </p>
          </div>
          <div class="level-item is-narrow has-text-centered">
            <p>
              <strong class="has-text-white is-block">Matthieu Ricard</strong>
              Mönch und Molekularbiologe
            </p>
          </div>
        </div>
      </div>
      
      <div class="hero-body">
        <div class="columns">
          <div class="column">
            <div class="eye"></div>
            <div class="logos">
              <b-image :src="require('@/assets/logo-berlinale-special-2021.svg')" alt="" class="berlinale" ratio="240by100"></b-image>
            </div>
          </div>
          <div class="column">
            <div class="brand">
              <b-image :src="require('@/assets/logo-werwirwaren.svg')" alt=""></b-image>
            </div>
          </div>
        </div>
      </div>

      <div class="hero-foot">
        <div class="buttons is-centered">
          <a href="#trailer" class="button is-black">Trailer</a>
          <a href="#doku" class="button is-black">Hintergrund</a>
          <a href="#info" class="button is-black is-outlined">Infos</a>
          <a href="#downloads" class="button is-black is-outlined">Schulmaterial</a>
          <a href="#actors" class="button is-black is-outlined">Protagonisten</a>
        </div>
      </div>
    </section>

    <section id="trailer" class="section container">
      <figure class="image is-16by9 has-background-black">
        <iframe class="has-ratio" width="560" height="315" data-name="youtube" data-src="https://www.youtube.com/embed/4lEBn56PxiY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </figure>
    </section>

    <section id="quotes" class="section container content">
      <h3 class="title is-2 has-text-weight-normal">"Ein poetischer Bilderrausch voller Hoffnung und Inspiration"</h3>
      <h4 class="subtitle is-spaced has-text-white is-italic">ZDF Aspekte</h4>
      <h3 class="title is-2 has-text-weight-normal">"Ein visuell atemberaubender Film"</h3>
      <h4 class="subtitle is-spaced has-text-white is-italic">Variety</h4>
    </section>

    <section id="cinema" class="section container content">
      <h2 class="is-size-2">Kinoticket buchen</h2>
      <figure class="image is-16by9 has-background-black mb-5" id="kinoheld-iframe">
        <iframe class="has-ratio" width="560" height="315" data-name="kinoheld" data-src="https://www.kinoheld.de/film/wer-wir-waren"></iframe>
      </figure>
      <div id="kinoheld-eframe" class="mb-5"></div>
      <p class="has-text-centered"><a href="https://www.x-verleih.de/filme/wer-wir-waren/#cinemafinder" target="_blank" rel="noopener" class="button is-black">Nichts dabei? Alle Kinotermine</a></p>
    </section>

    <section id="stream" class="section container content">
      <h2 class="is-size-2">Film streamen</h2>
      <figure class="image is-16by9 has-background-black mb-5" id="pantaflix-iframe">
        <iframe class="has-ratio" width="560" height="315" data-name="pantaflix" data-src="https://embed.cdn.pantaflix.com/latest/#/pantaflix/movie/764751?client=1711" allow="autoplay; encrypted-media; picture-in-picture" frameborder="0" allowfullscreen="true"></iframe>
      </figure>
    </section>

    <section id="doku" class="section container content">
      <h2 class="is-size-2">Hintergrund</h2>
      <figure class="image is-16by9 has-background-black" style="margin: 0">
        <iframe class="has-ratio" width="560" height="315" data-name="zdf" data-src="https://ngp.zdf.de/miniplayer/embed/?mediaID=%2Fzdf%2Fkultur%2Faspekte%2Fder-film-wer-wir-waren-was-wir-auf-der-erde-hinterlassen-100" allowfullscreen></iframe>
      </figure>
    </section>

    <section id="info" class="section container content">
      <blockquote>
        <p>„Wir waren jene, die wussten, aber nicht verstanden, voller Informationen, aber ohne Erkenntnis, randvoll mit Wissen, aber mager an Erfahrung. So gingen wir, von uns selbst nicht aufgehalten.“</p>
        <cite>Roger Willemsen</cite>
      </blockquote>
      <p>Was werden zukünftige Generationen über uns denken, wenn wir bereits Geschichte sind? In WER WIR WAREN blicken wir auf den gegenwärtigen Zustand der Welt und fragen uns im Geiste von Willemsens Vermächtnis, ob sie an uns verzweifeln werden. Sechs Denker*innen und Wissenschaftler*innen reflektieren die Gegenwart und blicken in die Zukunft.</p>
      <p>Produzent, Regisseur und Autor Marc Bauder begleitet seine Gesprächspartner*innen in die Tiefen des Ozeans, über das Dach der Welt, bis in die Weiten des Weltraums und beleuchtet dabei die unglaublichen Fähigkeiten des menschlichen Gehirns, einen globalen Wirtschaftsgipfel, das Erbe der Kolonialisierung und die Gefühle eines Roboters.</p>
      <p>Dieser inspirierende Blick auf unsere Welt hält eine hoffnungsvolle Botschaft für uns alle bereit: Wir selbst haben es in der Hand, wer wir sind, wenn wir unsere Verbundenheit, unsere Gemeinsamkeit, unser ›Wir‹ erkennen.</p>
      <p>WER WIR WAREN ist ein intensives Treffen mit sechs bedeutenden Denker*innen und Wissenschaftler*innen unserer Zeit:  <strong>Alexander Gerst</strong> (Astronaut), <strong>Dennis Snower</strong> (Ökonom), <strong>Matthieu Ricard</strong> (Molekularbiologe und Mönch), <strong>Sylvia Earle</strong> (Ozeanologin), <strong>Felwine Sarr</strong> (Ökonom, Soziologe und Philosoph) und <strong>Janina Loh</strong> (Philosophin und kritische Posthumanistin).</p>
    </section>

    <section id="downloads" class="section container content">
      <p class="buttons is-centered">
        <a href="downloads/Presseheft-WER-WIR-WAREN.pdf" target="_blank" rel="noopener" class="button is-black">Presseheft (PDF)</a>
        <a href="downloads/Schulheft-WER-WIR-WAREN.pdf" target="_blank" rel="noopener" class="button is-black">Schulheft (PDF)</a>
        <a href="downloads/Poster-WER-WIR-WAREN.pdf" target="_blank" rel="noopener" class="button is-black is-outlined">Poster (PDF)</a>
        <a href="downloads/Poster-WER-WIR-WAREN.jpg" target="_blank" rel="noopener" class="button is-black is-outlined">Poster (JPG)</a>
      </p>
    </section>

    <section id="carousel" class="section container">
      <b-carousel>
        <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
          <b-image :src="carousel.image" alt="" ratio="5by2"></b-image>
          <span v-if="carousel.title" :class="'carousel-item-title carousel-item-title-' + carousel.align"><strong>{{ carousel.title }}</strong> {{ carousel.subtitle }}</span>
        </b-carousel-item>
      </b-carousel>
    </section>

    <section id="actors" class="section container content">
        <h2 class="is-size-2">Protagonisten</h2>
        <b-collapse class="card" animation="slide" :open="false">
            <template #trigger="props">
              <div class="card-header" role="button">
                <span class="card-header-title">ALEXANDER GERST</span>
                <a class="card-header-icon"><b-icon size="is-medium" :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon></a>
              </div>
            </template>
            <div class="card-content content">
              <div class="columns">
               <div class="column is-4">
                <b-image :src="require('@/assets/carousel/Alexander-Gerst-1by1.jpg')" alt="" ratio="1by1"></b-image>
               </div>
               <div class="column">
                <p class="has-text-white">Astronaut</p>
                <p><em>„Wir Menschen realiseren, dass wenn wir unseren Planeten zerstören, und das ist leicht möglich, wenn man sich die Atmospähre anschaut (…) dann ist es vorbei mit uns, dann endet die Geschichte der Menschheit.“</em></p>
                <p>Bevor er sich mit den Weiten des Alls beschäftigt, untersucht der Astronaut Alexander Gerst (*1976) über Jahre als Geophysiker die gewaltigen Kräfte im Inneren der Erde: „Die Erste Sekunde einer Strombolianischen Vulkaneruption“ lautet der Titel seiner Dissertation an der Universität Hamburg. 2009 nimmt er seine Ausbildung zum Astronauten bei der Europäischen Weltraumorganisation ESA in Köln auf.</p>
                <p>2014 und 2018 war Alexander Gerst an Missionen zur Internationalen Raumstation ISS beteiligt – und hat auf der zweiten Reise drei Monate lang das Kommando über die ISS übernommen. Insgesamt war er 363 Tage im All: Mit seinen Fotos und Eindrücken hat er uns dabei immer wieder vor Augen geführt, wie einzigartig, schön und verletzlich unser Heimatplanet ist: das „Raumschiff Erde“.</p>
                <p>Für sein Engagement wurde Gerst vielfach ausgezeichnet, unter anderem zwei Mal mit dem Bundesverdienstkreuz. Als UNICEF-Botschafter setzt er sich für die Rechte und Chancen von Kindern in aller Welt ein.</p>
               </div>
              </div>
            </div>
        </b-collapse>
        <b-collapse class="card" animation="slide" :open="false">
            <template #trigger="props">
              <div class="card-header" role="button">
                <span class="card-header-title">SYLVIA EARLE</span>
                <a class="card-header-icon"><b-icon size="is-medium" :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon></a>
              </div>
            </template>
            <div class="card-content content">
              <div class="columns">
               <div class="column is-4">
                <b-image :src="require('@/assets/carousel/Sylvia-Earle-1by1.jpg')" alt="" ratio="1by1"></b-image>
               </div>
               <div class="column">
                <p class="has-text-white">Ozeanologin</p>
                <p><em>„All unsere Entscheidungen, was wir essen, was wir anziehen, welche Art Energie wir nutzen, ob wir Wasser verschwenden, fallen bei acht Milliarden Menschen wirklich ins Gewicht.“</em></p>
                <p>Sylvia Earle (*1935), vom Time Magazine als „Heldin für den Planeten“ bezeichnet, ist eine amerikanische Ozeanographin, Entdeckerin, Autorin und Dozentin. Bereits mit drei Jahren erlebt sie ihren Schlüsselmoment, der sie bis zum heutigen Tag unermüdlich ihrer Berufung, folgen lässt: Sie wurde von einer Welle des Atlantiks erfasst und verlor dabei fast ihr Leben. Dieses einschneidende Erlebnis war die Initialzündung für ihre lebensbegleitende Faszination und Leidenschaft die Weltmeere zu erforschen und zu schützen. 1979 lief die US-Biologin als erster Mensch in einer druckdichten Taucherausrüstung 381 Meter tief auf dem Grund des Atlantiks. Sieben Jahre später ist sie eine der ersten Testpilot*innen, die mit einem Tauchboot auf 1.000 Meter vorstießen.</p>
                <p>In ihrer Forschungskarriere hat die „Grand Dame der Meeresforschung“ mehr als 100 Ozeanexpeditionen geleitet und mindestens 7000 Stunden unter Wasser verbracht. Earles jahrzehntelange Erfahrungen, mit immer neuen technischen Mitteln in die unbekannten Tiefen der Weltmeere vorzustoßen, haben ihr u.a. einen Beratervertrag bei der NASA eingebracht. Allerdings, so sagt sie, sei es für sie noch immer unbegreiflich, dass Planeten wie Mars und Mond inzwischen deutlich besser kartographiert sind als die Weltmeere – obwohl diese 95 Prozent der gesamten Biosphäre der Erde einnehmen. Heute wirbt sie als Botschafterin der Meere mit ihrer Organisation Mission Blue um öffentliche Aufmerksamkeit und die dringend benötigte Unterstützung für die Erhaltung von Meeresschutzgebieten, den sogenannten Hope Spots („Orte der Hoffnung“).</p>
               </div>
              </div>
            </div>
        </b-collapse>
        <b-collapse class="card" animation="slide" :open="false">
            <template #trigger="props">
              <div class="card-header" role="button">
                <span class="card-header-title">DENNIS SNOWER</span>
                <a class="card-header-icon"><b-icon size="is-medium" :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon></a>
              </div>
            </template>
            <div class="card-content content">
              <div class="columns">
               <div class="column is-4">
                <b-image :src="require('@/assets/carousel/Dennis-Snower-1by1.jpg')" alt="" ratio="1by1"></b-image>
               </div>
               <div class="column">
                <p class="has-text-white">Ökonom</p>
                <p><em>„Meiner Ansicht nach wäre es wirklich wichtig, dass Kinder in einem System aufwachsen, bei dem im Kopf die Alarmglocken läuten, wenn sie Sätze hören wie: »Amerika zuerst«, »Baut eine Mauer« oder »Ausländer raus!« Sie sollten sofort erkennen: »Ah, das ist ja genau das Gegenteil von dem, was wir brauchen.« Doch kaum jemand in der westlichen Welt wird so ausgebildet. Kaum jemand weiß, welche Narrative die Kooperation fördern und welche zu Konflikten führen. Und deshalb sollte die Bedeutung der Kooperation für die Lösung wichtiger Probleme, die alle Gesellschaften betreffen, in den Lehrplan aufgenommen werden.“</em></p>
                <p>Dennis Snower (*1950) studiert an der Universität Oxford Philosophie, Politik- und Wirtschaftswissenschaften und promoviert an der Universität Princeton in Volkswirtschaftslehre. Einst war er ein klassischer Verfechter des Neoliberalismus mit seinem Menschenbild des „Homo oeconomicus“ – einem Menschen also, der stets in erster Linie seinen Gewinn maximieren will. Heute jedoch setzt er sich für einen Paradigmenwechsel ein und fordert, dass die Weltwirtschaft nicht länger unter Auslassung der Erkenntnisse von Ökologie, Psychologie, Neurowissenschaft, Anthropologie und Soziologie funktionieren darf, sondern in der Pflicht für eine stärkere soziale Verantwortung steht.</p>
                <p>In seiner 15-jährigen Präsidentschaft an der Spitze des Kieler Instituts für Weltwirtschaft (IfW) initiierte der gebürtige Österreicher den „Weltwirtschaftlichen Preis“, eine Auszeichnung, die das IfW jährlich an Vordenker einer weltoffenen, marktwirtschaftlichen und sozialen Gesellschaft vergibt.</p>
                <p>2019 verlässt Dennis Snower das Kieler Institut und befindet sich seitdem im aktiven Ruhestand. So steht er als Präsident dem 2019 von ihm gegründeten, internationalen Think Tank-Netzwerk Global Solutions Initiative vor. Die Initiative ist mittlerweile eine Art intellektuelles Rückgrat der G20 geworden, die sich zur Lösung globaler Probleme für den aktiven Austausch zwischen Wissenschaft, Politik, Wirtschaft und Zivilgesellschaft einsetzt. Er ist auch Professor an der Hertie School of Governance in Berlin und Forscher an der Oxford Universität, Brookings Institution in Washington D.C. und The New Institute in Hamburg.</p>
                <p>Dennis Snower, der auch Gedichte schreibt und als klassischer Pianist aufgetreten ist, ist Gastprofessor an zahlreichen renommierten Universitäten und berät internationale Organisationen und Regierungen.</p>
               </div>
              </div>
            </div>
        </b-collapse>
        <b-collapse class="card" animation="slide" :open="false">
            <template #trigger="props">
              <div class="card-header" role="button">
                <span class="card-header-title">MATTHIEU RICARD</span>
                <a class="card-header-icon"><b-icon size="is-medium" :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon></a>
              </div>
            </template>
            <div class="card-content content">
              <div class="columns">
               <div class="column is-4">
                <b-image :src="require('@/assets/carousel/Matthieu-Ricard-1by1.jpg')" alt="" ratio="1by1"></b-image>
               </div>
               <div class="column">
                <p class="has-text-white">Buddhist & Molekularbiologe</p>
                <p><em>„Wahre Freiheit ist, das Ruder in die Hand zu nehmen, die Segel zu hissen und dahin zu steuern, wohin man möchte. Die Freiheit zu haben, das Ziel zu erreichen.“</em></p>
                <p>Matthieu Ricard (*1946), Sohn der Künstlerin Yahne Le Toumelin und Jean-François Revel, einem der führenden Intellektuellen Frankreichs, studiert Molekularbiologie und promoviert bei Nobelpreisträger Francois Jacob. Es folgt eine beeindruckende Karriere in Frankreich, bis Ricard 1970 der klassischen Wissenschaft den Rücken kehrt und seinen spirituellen Weg als buddhistischer Mönch in Nepal antritt.</p>
                <p>Als Mitglied des Mind & Life Institute, einer Organisation, die sich dem Dialog zwischen Forschung und Buddhismus widmet, arbeitet er mit Wissenschaftlern*innen aus dem Bereich der Hirnforschung an der Wirkung von Meditation auf das menschliche Gehirn. Wahrscheinlich ist auch deshalb sein Gehirn eines der am besten erforschten der Welt – Ricard verbrachte hunderte Stunden als Proband im MRT. Die Erkenntnis: Fundamentale menschliche Eigenschaften wie Uneigennützigkeit, innerer Frieden und emotionale Ausgeglichenheit können, wie viele andere Dinge auch, tatsächlich trainiert werden.</p>
                <p>Matthieu Ricard ist offizieller Französisch-Übersetzer des Dalai Lama sowie Fotograf und Autor zahlreicher Bestseller. In seinen Büchern beschäftigt er sich mit der Ausrichtung der Weltwirtschaft auf ein stärkeres Miteinander, mehr Empathie und Rücksichtnahme. Er lebt zurückgezogen am Fuße des Himalaya.</p>
               </div>
              </div>
            </div>
        </b-collapse>
        <b-collapse class="card" animation="slide" :open="false">
            <template #trigger="props">
              <div class="card-header" role="button">
                <span class="card-header-title">FELWINE SARR</span>
                <a class="card-header-icon"><b-icon size="is-medium" :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon></a>
              </div>
            </template>
            <div class="card-content content">
              <div class="columns">
               <div class="column is-4">
                <b-image :src="require('@/assets/carousel/Felwine-Sarr-1by1.jpg')" alt="" ratio="1by1"></b-image>
               </div>
               <div class="column">
                <p class="has-text-white">Ökonom, Soziologe und Philosoph</p>
                <p><em>„Letztlich geht es um den Aufbau einer Menschengemeinschaft, um ein globales ‚Wir‘.“</em></p>
                <p>Felwine Sarr (*1972), aufgewachsen auf zwei Kontinenten zwischen Strasbourg und Dakar, gilt als einer der meistdiskutierten und wichtigsten Denker Afrikas. Der gebürtige Senegalese ist Professor für Ökonomie an der Gaston-Berger-Universität in Saint-Louis und unterrichtet Romanistik an der Duke University in den USA. Zu seinen Forschungsschwerpunkten gehören politische Ökonomie sowie Makro- und Entwicklungsökonomie. Außerdem arbeitet er als Sozialwissenschaftlerwissenschaftler, Buchautor und Musiker.</p>
                <p>In seinem vielbeachteten Werk „Afrotopia“ (2019) beschäftigt sich Felwine Sarr mit der Entkolonialisierung Afrikas im Zuge einer Rückbesinnung auf die geistigen Ressourcen, das kulturelle Erbe, die Identität des Kontinents. Und er geht der drängenden Frage nach, wie koloniales Gedankengut Europas Haltung und Handeln gegenüber Afrika prägt.</p>
                <p>Mit der französischen Kunsthistorikerin Bénédicte Savoy wird er im selben Jahr von Frankreichs Präsident Emmanuel Macron beauftragt, die Rückgabe von französischer Raubkunst nach Afrika vorzubereiten.</p>
                <p>Felwine Sarr beschäftigt sich grundlegend mit der Frage, wie ein neu interpretiertes „Wir“ zwischen Europa und Afrika entstehen kann. Dabei stehen für ihn auch die Folgen der Klimakrise und die Suche nach einem, an den Bedürfnissen aller orientierten, Konzepte für einen ganzheitlichen Wohlstand im Fokus.</p>
               </div>
              </div>
            </div>
        </b-collapse>
        <b-collapse class="card" animation="slide" :open="false">
            <template #trigger="props">
              <div class="card-header" role="button">
                <span class="card-header-title">JANINA LOH</span>
                <a class="card-header-icon"><b-icon size="is-medium" :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon></a>
              </div>
            </template>
            <div class="card-content content">
              <div class="columns">
               <div class="column is-4">
                <b-image :src="require('@/assets/carousel/Janina-Loh-1by1.jpg')" alt="" ratio="1by1"></b-image>
               </div>
               <div class="column">
                <p class="has-text-white">Philosophin und kritische Posthumanistin</p>
                <p><em>„Wenn ich mir eine Gesellschaft in hundert Jahren vorstelle, die zurückschaut, würde ich hoffen, dass wir es als Gesellschaft geschafft haben werden, von diesem exklusiven Denken, das die Menschen in den Mittelpunkt stellt, abzukommen.“</em></p>
                <p>Janina Loh (*1984) ist Technikphilosophin und kritische Posthumanistin an der Universität Wien. Bereits ihr Masterabschluss an der Humboldt-Universität zu Berlin mit einer Arbeit über das Verantwortungskonzept bei Hannah Arendt wurde ausgezeichnet. Das Thema „Verantwortung" hat seitdem eine nur schwer zu überbietende Rolle in Lohs Forschung. </p>
                <p>2019 veröffentlichte sie ihre Schrift zur Roboterethik und geht darin der Frage nach, welchen ethischen Herausforderungen in Bau, Weiterentwicklung und Umgang mit Robotern wir gegenüberstehen. Die Übermacht der Datenriesen und fortschreitende Digitalisierung sind für Loh Momentaufnahmen und problematische Entwicklungsstadien, die es auf dem Weg in eine anti-kapitalistische Zukunft zu überwinden gilt. Loh vertritt die Position, dass Technik nie neutral ist, da diese stets von uns Menschen zu einem bestimmten Zweck erschaffen wird. Sie ist davon überzeugt, dass die Menschen die Verantwortung für ihre Technologien nicht einfach abgeben können.</p>
                <p>Ihre Dissertation Verantwortung als Begriff, Fähigkeit, Aufgabe. Eine Drei-Ebenen-Analyse (2014) ist ihre erste große Studie zur Verantwortung. Lohs Monographie zum Trans- und Posthumanismus (2018) ist die erste deutschsprachige Einführung in die Auseinandersetzung mit diesen Strömungen.</p>
               </div>
              </div>
            </div>
        </b-collapse>
    </section>

    <section id="sponsors" class="section container content">
      <div class="columns has-text-centered">
        <div class="column">
          <b-image :src="require('@/assets/siegel-pbw.png')" alt="" ratio="1by1"></b-image>
          <p>Ausgezeichnet mit dem Prädikat BESONDERS WERTVOLL der Deutschen Film- und Medienbewertung, Wiesbaden.</p>
        </div>
        <div class="column">
          <b-image :src="require('@/assets/logo-berlinale-special-2021.svg')" alt="" ratio="1by1"></b-image>
          <p>Unser Film WER WIR WAREN wird bei den 71. Internationalen Filmfestspielen Berlin 2021 als Special-Beitrag gezeigt.</p>
        </div>
      </div>
    </section>

    <section id="book" class="section container content">
      <h2 class="is-size-2">Das Buch zum Film</h2>
      <div class="columns is-centered">
        <div class="column is-5">
          <b-image :src="require('@/assets/book/cover.png')" alt="" ratio="5by6"></b-image>
        </div>
      </div>
      <div class="columns is-centered is-multiline">
        <div class="column is-half">
          <b-image :src="require('@/assets/book/page1.jpg')" alt="" ratio="5by3"></b-image>
        </div>
        <div class="column is-half">
          <b-image :src="require('@/assets/book/page2.jpg')" alt="" ratio="5by3"></b-image>
        </div>
        <div class="column is-half">
          <b-image :src="require('@/assets/book/page3.jpg')" alt="" ratio="5by3"></b-image>
        </div>
        <div class="column is-half">
          <b-image :src="require('@/assets/book/page4.jpg')" alt="" ratio="5by3"></b-image>
        </div>
      </div>
    </section>

    <section id="newsletter" class="section container content">
      <h2 class="is-size-2">Nichts verpassen</h2>
      <p class="has-text-centered">In unserem Newsletter erfahren Sie alles rund um unsere Filme - aktuelle Kinostarts, neue DVDs, Dreharbeiten, Previews in Ihrer Stadt und erhalten einen Blick hinter die Kulissen von X Verleih.</p>
      <p class="has-text-centered">
        <a href="https://www.x-verleih.de/newsletter/" target="_blank" rel="noopener" class="button is-black">Newsletter abonnieren</a>
      </p>
    </section>
  </standard>
</template>

<script>
  import Standard from '../layouts/standard.vue'

  export default {
    components: {
      Standard
    },
    data() {
      return {
        isLoading: true,
        carousels: [
          { image: require('@/assets/carousel/Planet-Erde.jpg'), alt: '', align: 'center', title: '', subtitle: '' },
          { image: require('@/assets/carousel/Alexander-Gerst.jpg'), alt: '', align: 'center', title: 'Alexander Gerst', subtitle: '' },
          { image: require('@/assets/carousel/Sylvia-Earle.jpg'), alt: '', align: 'right', title: 'Sylvia Earle', subtitle: '' },
          { image: require('@/assets/carousel/Felwine-Sarr.jpg'), alt: '', align: 'left', title: 'Felwine Sarr', subtitle: '' },
          { image: require('@/assets/carousel/Taucher.jpg'), alt: '', align: 'right', title: '', subtitle: '' },
          { image: require('@/assets/carousel/Dennis-Snower.jpg'), alt: '', align: 'right', title: 'Dennis Snower', subtitle: '' },
          { image: require('@/assets/carousel/Janina-Loh.jpg'), alt: '', align: 'left', title: 'Janina Loh', subtitle: '' },
          { image: require('@/assets/carousel/Matthieu-Ricard.jpg'), alt: '', align: 'right', title: 'Matthieu Ricard', subtitle: '' },
          { image: require('@/assets/carousel/Angela-Merkel.jpg'), alt: '', align: 'right', title: '', subtitle: '' }
        ]
      }
    },
    mounted() {
      setTimeout(() => {
        this.isLoading = false}, 1000) /* hide loading after a second */
      }
  }
</script>
