<template>
  <div id="app">
    <main>
      <slot></slot>

      <aside class="buttons is-centered">
        <a href="https://de-de.facebook.com/xverleih/" target="_blank" rel="noopener" class="button is-text"><b-icon icon="facebook" size="is-medium"></b-icon></a>
        <a href="http://twitter.com/xverleih/" target="_blank" rel="noopener" class="button is-text"><b-icon icon="twitter" size="is-medium"></b-icon></a>
        <a href="https://www.youtube.com/user/xverleih" target="_blank" rel="noopener" class="button is-text"><b-icon icon="youtube" size="is-medium"></b-icon></a>
        <a href="https://www.instagram.com/xverleih/" target="_blank" rel="noopener" class="button is-text"><b-icon icon="instagram" size="is-medium"></b-icon></a>
      </aside>
    </main>

    <Footer/>
  </div>
</template>

<style lang="scss">
  @import "@/scss/_variables.scss";
  @import "~bulma";
  @import "~buefy/src/scss/buefy";
  @import "~klaro/src/scss/klaro";
  @import "@/scss/custom.scss";
  @import "@/scss/fonts.scss";
  @import "@/scss/materialdesignicons.scss";
</style>

<script>
  import Footer from '@/components/footer.vue'

  export default {
    components: {
      Footer
    }
  }
</script>
