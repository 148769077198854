import Vue from 'vue'
import Buefy from 'buefy'
import * as Klaro from 'klaro/dist/klaro-no-css'

import routes from './routes'
import errorView from './pages/404.vue'

Vue.config.productionTip = false
Vue.use(Buefy)

const app = new Vue({
  el: '#app',
  data: {
    currentRoute: window.location.pathname
  },
  computed: {
    ViewComponent () {
      const base = process.env.BASE_URL.slice(0, -1)
      const route = this.currentRoute.replace(base, '')

      const matchingView = routes[route]
      return matchingView ? matchingView : errorView
    }
  },
  render (h) {
    return h(this.ViewComponent)
  }
})

window.addEventListener('popstate', () => {
  app.currentRoute = window.location.pathname
})


/* Klaro! A Simple Consent Manager */
const config = {
    acceptAll: true,
    mustConsent: true,
    translations: {
      zz: {
          privacyPolicyUrl: 'https://www.x-verleih.de/datenschutzerklaerung/',
      }
    },
    services: [
        {
            name: 'google-tag-manager',
            purposes: ['functional'],
            required: true
        },
        {
            name: 'google-analytics',
            purposes: ['marketing'],
            cookies: [
                /^_ga(_.*)?/ // we delete the Google Analytics cookies if the user declines its use
            ],
            callback: function(consent, service) {
                serviceCallback(consent, service)
            }
        },
        {
            name: 'facebook',
            purposes: ['advertising'],
            callback: function(consent, service) {
                serviceCallback(consent, service)
            }
        },
        {
            name: 'youtube',
            purposes: ['marketing'],
            callback: function(consent, service) {
                serviceCallback(consent, service)
            }
        },
        {
            name: 'zdf',
            purposes: ['marketing'],
            callback: function(consent, service) {
                serviceCallback(consent, service)
            }
        },
        {
            name: 'pantaflix',
            purposes: ['marketing'],
            callback: function(consent, service) {
                serviceCallback(consent, service)
            }
        },
        {
            name: 'kinoheld',
            purposes: ['marketing'],
            callback: function(consent, service) {
                serviceCallback(consent, service)
                if ( consent ) {
                    document.getElementById('kinoheld-iframe').style.display = 'none';
                    document.getElementById('kinoheld-eframe').style.display = 'block';
                } else {
                    document.getElementById('kinoheld-iframe').style.display = 'block';
                    document.getElementById('kinoheld-eframe').style.display = 'none';
                }
            }
        },
    ]
};

window.klaro = Klaro;
window.klaroConfig = config;
Klaro.setup(config);

/* Track inside iframe click in GTM (see https://medium.com/@rafaelperozin/track-the-click-on-third-party-iframe-with-gtm-using-custom-javascript-trigger-20ab80bfeb5) */
window.focus();

// When click on iframe the focus change and we are listen this
var listener = window.addEventListener('blur', function() {
    var active = document.activeElement;
    var tag = document.activeElement.tagName.toLowerCase();
    var service = active.dataset.name || ''; /* get [data-name] which is a klaro service */

    if (service != '') {
        pushEvent(tag + '-' + service + '-click');
    }

    setTimeout(function(){ window.focus(); console.log(document.activeElement.tagName.toLowerCase()) }, 2000);
    window.removeEventListener('blur', listener);
});

function serviceCallback(consent, service) {
    if ( consent ) {
        pushEvent('klaro-' + service.name + '-accepted');
    }
    console.log( 'KLARO  ' + service.name + ' [consent=' + consent + ']');
}

function pushEvent(name) {
    window.dataLayer = window.dataLayer || []; /* GTM */
    window.dataLayer.push({'event': name}); /* register GTM event */
    console.log( 'GTM  push event [' + name + ']');
}


/* GTM: trigger before leaving page */
window.addEventListener('beforeunload', function() {
    pushEvent('page-beforeunload');
});


/* Smooth scroll */
const links = document.querySelectorAll('a[href^="#"]');

for (const link of links) {
  link.addEventListener("click", clickHandler);
}

function clickHandler(e) {
  e.preventDefault();
  const href = this.getAttribute("href");
  const offsetTop = document.querySelector(href).offsetTop;

  scroll({
    top: offsetTop,
    behavior: "smooth"
  });
}
